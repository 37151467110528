{
  "FUSIONAUTH_AUTHORITY": "https://auth.sitemate.com",
  "NODE_ENV": "qa",
  "GLOBAL_API_URL": "https://api-global-qa.hseqai.com/",
  "SITEMATE_API_URL": "https://api-qa-uk1.hseqai.com/",
  "FUSIONAUTH_FLOWSITE_APPLICATION_ID": "c726bcce-4392-4cb7-93b1-716a7dfb7934",
  "FUSIONAUTH_DASHPIVOT_APPLICATION_ID": "cd0685a0-78d9-4d42-9d74-5658bd70d3c5",
  "DASHPIVOT_CALLBACK_PATH": "/oauth-callback/dashpivot",
  "XERO_CLIENT_PKCE": "A503BA1E290B4C8EAC93823712AA62DF",
  "XERO_CALLBACK_PATH": "/oauth-callback/xero",
  "QUICKBOOKS_CLIENT_ID": "ABo501AhhYUTvvff4DefklS5GcRmqw7lFxPq3EL4RJvWhZ1I4h",
  "QUICKBOOKS_CALLBACK_PATH": "/oauth-callback/quickbooks",
  "MICROSOFT_CLIENT_ID": "fce74a54-65de-49c1-813b-ecfeb62cac51",
  "MICROSOFT_CALLBACK_PATH": "/oauth-callback/microsoft",
  "BUGSNAG_ANGULAR_API_KEY": "e9c9d061aec65357b003561fd7a09ec3"
}
